import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook, Worksheet } from 'exceljs';
import { firstValueFrom } from 'rxjs';
import * as saveAs from 'file-saver';

import { Connection } from '@app/@shared/model/connection.model';
import { Device } from '@app/@shared/model/device.model';
import { Splitter } from '@app/@shared/model/splitter.model';
import { ConnectionService } from '@app/@shared/services/map-elements-services/connection.service';
import { ExcelJSHelperService } from '@app/@shared/services/aux-services/excelJS-helper.service';
import { SplitterService } from '@app/@shared/services/map-elements-services/splitter.service';
import { IRedirectSettings } from '@app/@shared/model/interface/iRedirect-settings';
import { Router } from '@angular/router';
import { WindowService } from '@app/@shared/services/cordova/window.service';
import { DeviceBoxComponent } from './device-box/device-box.component';
import { SplitterBoxComponent } from '../splitter-detail/splitter-box/splitter-box.component';
import html2canvas from 'html2canvas';
import { environment } from '@env/environment';
import { HTMLtoSVGDownlaodService } from '@app/@shared/services/d3/html-to-svg-download.service';


declare global {
  interface Window {
    html2canvas: any;
  }
}


@Component({
  selector: 'device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss'],
})
export class DeviceDetailComponent implements OnDestroy {

  @ViewChild(DeviceBoxComponent, { static: false }) deviceBoxComponent: DeviceBoxComponent;
  @ViewChild(SplitterBoxComponent, { static: false }) splitterBoxComponent: SplitterBoxComponent;

  @Input() connectionsAreSelectable: boolean = true;
  @Output() connectionSelected = new EventEmitter<Connection>();

  downloadingFiles: boolean = false;
  deviceToDownload: Device[] = undefined;
  splittersToDownload: Device = undefined;

  selectedConnectionKey: number[] = [];
  selectedConnection: Connection[] = [];

  ds_accordion_element = [];
  accordion_element_selected = [];
  ds_accordion_connections: { title: string; data: Connection[] }[] = [];
  ds_accordion_connections_selected: { title: string; data: Connection[] }[] = [];

  ds_accordion_splitter = [];
  ds_accordion_splitter_selected = [];

  selectedSplitter: Splitter;

  projectDataDetail: any;

  scriptSrcHtml2Canvas = environment.scriptSrcHtml2Canvas;

  _entity: Device;
  projectDataDetailDescr2: any;
  @Input('entity')
  get entity() {
    return this._entity;
  }
  set entity(value) {
    if (value == this._entity) {
      return;
    }
    this._entity = value;
    this.projectDataDetail = this._entity.deviceAttributes;
    this.projectDataDetailDescr2 = this._entity.deviceAttributes.descr2;
    this.loadEntity();
  }

  @Input() isDetailView = false;
  @Output() onDetailClosed = new EventEmitter<any>();
  @Output() collapseDetail = new EventEmitter<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private connectionService: ConnectionService,
    private excelJSHelperService: ExcelJSHelperService,
    private router: Router,
    private splitterService: SplitterService,
    private trans: TranslateService,
    private windowService: WindowService,
    private htmlToSVGDownlaodService: HTMLtoSVGDownlaodService
  ) { }

  ngOnDestroy(): void {
    if (this.selectedConnection.length) {
      this.connectionSelected.emit(undefined);
    }
  }

  loading_connections = false;

  async loadEntity() {
    this.collapseAccordions();
    this.loadDetailConnections();
    this.loadDeviceAccordion();
    await this.loadSplitterAccordion();
  }

  collapseAccordions = () => {
    this.collapseSplitterAccordions();
    this.collapseDeviceAccordions();
  };

  collapseDeviceAccordions = () => {
    this.accordion_element_selected = [];
    this.ds_accordion_connections_selected = [];

    if (this.isDetailView) {
      this.collapseDetail.emit();
    }
  };

  collapseSplitterAccordions = () => {
    this.selectedSplitter = undefined;
  };

  loadDeviceAccordion = () => {
    //Close search panel
    this.ds_accordion_element = [];

    this.ds_accordion_element.push({
      title:
        String.prototype.upperFirstLetter(this.entity.entityName) +
        ' ' +
        this.trans.instant('general.review'),
      data: this.entity,
    });

    this.accordion_element_selected = this.ds_accordion_element;
  };

  loadSplitterAccordion = async () => {
    const splitters = await firstValueFrom(
      this.splitterService.getWithFilter({ deviceID: this.entity.deviceID })
    );

    this.entity.splitter = splitters;
    this.ds_accordion_splitter = [
      {
        title: this.trans.instant('comp.device-detail.splitters'),
        data: splitters,
      },
    ];
  };

  loadDetailConnections() {
    setTimeout(() => {
      this.loading_connections = true;
    }, 500);

    this.connectionService.getByDeviceID(this.entity.deviceID, 'FIBER_FIBER')
      .subscribe({
        next: (res) => {
          setTimeout(() => {
            this.loading_connections = false;
          }, 500);
          if (res) {
            this.entity.connection = res;
            this.entity.connection.forEach(c => {
              if (c.elementID1_Rel.fiberAttributes == undefined) {
                c.elementID1_Rel.fiberAttributes = JSON.parse(c.elementID1_Rel.fiberData);
              }
              if (c.elementID2_Rel.fiberAttributes == undefined) {
                c.elementID2_Rel.fiberAttributes = JSON.parse(c.elementID2_Rel.fiberData);
              }
            });

            this.ds_accordion_connections = [];
            this.ds_accordion_connections.push({
              title: `${String.prototype.upperFirstLetter(
                this.entity.entityName
              )} ${this.trans.instant('comp.search-panel.connections')}`,
              data: this.entity.connection,
            });

            this.ds_accordion_connections_selected = this.ds_accordion_connections;
          }
        },
        error: (err) => {
          setTimeout(() => {
            this.loading_connections = false;
          }, 500);
        },
        complete: () => {
          setTimeout(() => {
            this.loading_connections = false;
          }, 500);
        }
      });
  }

  connectionRowClicked(rowData: any) {
    const dataGrid = rowData.component;
    const selectedKeys: number[] = dataGrid.getSelectedRowKeys() ?? [];
    const selectedConnections: Connection[] = dataGrid.getSelectedRowsData();

    // User can select only one row at a time.
    const sameKeyIsAlreadySelected = !!this.selectedConnectionKey.length && this.selectedConnectionKey[0] === selectedKeys[0];

    if (sameKeyIsAlreadySelected) {
      dataGrid.deselectRows(selectedKeys);
      this.selectedConnection = [];
      this.selectedConnectionKey = [];
    } else {
      this.selectedConnection = selectedConnections;
      this.selectedConnectionKey = selectedKeys;
    }

    this.connectionSelected.emit(this.selectedConnection[0]);
  }

  closeDetail() {
    this.onDetailClosed.emit(true);
  }

  log($event) {
    console.log($event);
  }

  onExporting(e) {
    const connectionsLabel = this.trans.instant(
      'comp.search-panel.connections'
    );
    const gridName = `${this.entity.name}_${connectionsLabel}`;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(gridName);

    exportDataGrid({
      component: e.component,
      worksheet,
      topLeftCell: { row: 2, column: 1 },
    }).then(() => {
      this.initializeExcelHeaders(worksheet);
      this.writeExcelData(worksheet);
      this.excelJSHelperService.adjustColumnsWidth(worksheet);

      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${gridName}.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  initializeExcelHeaders = (worksheet: Worksheet) => {
    const traceLabel = this.trans.instant('comp.search-panel.excel-header.trace')?.toUpperCase();
    const bufferLabel = this.trans.instant('comp.search-panel.excel-header.buffer')?.toUpperCase();
    const colorLabel = this.trans.instant('comp.search-panel.excel-header.color')?.toUpperCase();
    const serviceLabel = this.trans.instant('comp.search-panel.excel-header.service')?.toUpperCase();
    const term1Label = this.trans.instant('comp.search-panel.excel-header.term1')?.toUpperCase();
    const term2Label = this.trans.instant('comp.search-panel.excel-header.term2')?.toUpperCase();
    const oldNameLabel = this.trans.instant('comp.search-panel.excel-header.old-name')?.toUpperCase();
    const bufferIndexLabel = this.trans.instant('comp.search-panel.excel-header.buffer-index')?.toUpperCase();
    const fiberIndexLabel = this.trans.instant('comp.search-panel.excel-header.fiber-index')?.toUpperCase();
    const fiberOrderLabel = this.trans.instant('comp.search-panel.excel-header.fiber-order')?.toUpperCase();

    const headers = [
      term1Label,
      oldNameLabel,
      traceLabel,
      fiberOrderLabel,
      bufferLabel,
      bufferIndexLabel,
      colorLabel,
      fiberIndexLabel,
      serviceLabel,
      bufferLabel,
      bufferIndexLabel,
      colorLabel,
      fiberIndexLabel,
      oldNameLabel,
      traceLabel,
      fiberOrderLabel,
      term2Label
    ];

    this.excelJSHelperService.writeHeaders(worksheet, headers, 1);
    this.centerTextExcel(worksheet, 1, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]);

  };

  writeExcelData = (worksheet: Worksheet) => {
    const connections: Connection[] = this.ds_accordion_connections[0].data;

    const excelData: string[][] = connections.map((x) => {
      const formatName = (name: string) =>
        name.includes(' [') ? name.split(' [')[0] : name;

      const getServiceName = () => {
        const serviceName1 = x.elementID1_Rel?.serviceName;
        const serviceName2 = x.elementID2_Rel?.serviceName;

        return [...new Set([serviceName1, serviceName2])]
          .filter((x) => !!x)
          .join(' ,');
      };

      const term1 = x.elementID1_Rel.term1;
      const description1 = x.elementID1_Rel.T1[0].description;
      const trace1 = formatName(x.elementID1_Rel.name);
      const fiberOrder1 = x.elementID1_Rel.fiberOrder;
      const buffer1 = x.elementID1_Rel.fiberAttributes.buffer;
      const bufferIndex1 = x.elementID1_Rel.T1[0].BufferIndex;
      const color1 = x.elementID1_Rel.fiberAttributes.color;
      const fiberIndex1 = this.getFiberIndex(x.elementID1_Rel.T1[0].children, x.elementID1_Rel.fiberID);
      const service = getServiceName();
      const color2 = x.elementID2_Rel.fiberAttributes.color;
      const fiberIndex2 = this.getFiberIndex(x.elementID2_Rel.T2[0].children, x.elementID2_Rel.fiberID);
      const buffer2 = x.elementID2_Rel.fiberAttributes.buffer;
      const bufferIndex2 = x.elementID2_Rel.T2[0].BufferIndex;
      const trace2 = formatName(x.elementID2_Rel.name);
      const description2 = x.elementID2_Rel.T2[0].description;
      const fiberOrder2 = x.elementID2_Rel.fiberOrder;
      const term2 = x.elementID2_Rel.term2;



      return [term1, description1, trace1, fiberOrder1, buffer1, bufferIndex1, color1, fiberIndex1, service, buffer2, bufferIndex2, color2, fiberIndex2, description2, trace2, fiberOrder2, term2];
    });

    this.excelJSHelperService.writeData(worksheet, 2, excelData);
    this.centerTextExcel(worksheet, 1, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17], connections.length);

  };

  splitterSelected = (event) => {
    const splitter: Splitter = event.data;
    this.collapseDeviceAccordions();
    this.selectedSplitter = splitter;
  };

  deviceView() {
    const redirectSettings: IRedirectSettings = {
      path: `${this.router.url}/device/${this.entity.deviceID}`,
      newTab: true,
      external: false,
    };
    this.windowService.redirectTo(redirectSettings)
  }
  async downloadAndGenerateImage() {
    this.downloadingFiles = true;
    await this.htmlToSVGDownlaodService.downloadAllSVGs(this.entity);
    this.downloadingFiles = false;

  }



  prepareToDownloadDevice(device: Device) {
    this.downloadingFiles = true;
    this.deviceToDownload = [device];
  }

  async downloadDevice() {
    if (!this.deviceBoxComponent) {
      return;
    }

    this.changeDetector.detectChanges();

    try {
      await this.deviceBoxComponent.downloadAllDevicesGraph();
    } catch (ex) {
      console.log('ex', ex);
    } finally {
      this.deviceToDownload = undefined;
      this.downloadingFiles = false;
    }
  }

  splitterView() {
    const redirectSettings: IRedirectSettings = {
      path: `${this.router.url}/splitter/${this.entity.deviceID}`,
      newTab: true,
      external: false,
    };
    this.windowService.redirectTo(redirectSettings)
  }

  prepareToDownloadSplitter() {
    this.downloadingFiles = true;
    this.splittersToDownload = this.entity;
  }

  async downloadSplitters() {
    if (!this.splitterBoxComponent) {
      return;
    }

    this.changeDetector.detectChanges();

    try {
      await this.splitterBoxComponent?.downloadGraph();
    } catch (ex) {
      console.log('ex', ex);
    } finally {
      this.splittersToDownload = undefined;
      this.downloadingFiles = false;
    }
  }

  getFiberIndex = (childrenString, fiberID) => {
    const children = JSON.parse('[' + childrenString + ']');
    return children.findIndex(child => child.fiberID === fiberID) + 1;
  }

  centerTextExcel(worksheet: Worksheet, startRow: number, cells: number[], rowCount: number = 1) {
    for (let i = 0; i < rowCount; i++) {
      const row = worksheet.getRow(startRow + i);
      cells.forEach((cell) => {
        row.getCell(cell).alignment = { horizontal: 'center' };
      });
    }
  }
}
