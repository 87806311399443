import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { MainService } from '../@shared/services/main.service';
import { Shell } from '@app/shell/shell.service';
import { MapViewerComponent } from './map-viewer/map-viewer.component';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import { UtilsService } from '@app/@shared/services/aux-services/utils.service';
import { TraceService } from '@app/@shared/services/map-elements-services/trace.service';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { InfrastructureService } from '@app/@shared/services/map-elements-services/infrastructure.service';
import { ConnectionService } from '@app/@shared/services/map-elements-services/connection.service';
import { ServiceService } from '@app/@shared/services/map-elements-services/service.service';
import { HomeSurveyComponent } from './survey/home/home-survey.component';
import { SearchPanelDataResolver } from './map-viewer/resolver/map-viewer-data.resolver';
import { FeasibilityHomeComponent } from './feasibility/home/feasibility-home.component';
import { DeviceHomeComponent } from './device/home/device-home.component';
import { DevicesAndContainersResolver } from './device/resolver/devices-and-containers.resolver';
import { InfrastructureODFComponent } from '@app/@shared/components/search-panel/infrastructure-detail/infrastructure-odf/infrastructure-odf.component';
import { SplitterBoxComponent } from '@app/@shared/components/search-panel/splitter-detail/splitter-box/splitter-box.component';
import { InfrastructureResolver } from '@app/@shared/components/search-panel/infrastructure-detail/infrastructure-odf/resolver/infrastructure.resolver';
import { InfrastructureODFResolver } from '@app/@shared/components/search-panel/infrastructure-detail/infrastructure-odf/resolver/infrastructure-odf.resolver';
import { DeviceBoxComponent } from '@app/@shared/components/search-panel/device-detail/device-box/device-box.component';
import { CommonDevicesResolver } from '@app/@shared/components/search-panel/device-detail/device-box/resolver/common-devices.resolver';
import { SplitterBoxResolver } from '@app/@shared/components/search-panel/splitter-detail/splitter-box/resolver/splitter-box.resolver';
import { DeviceResolver } from '@app/@shared/components/search-panel/splitter-detail/splitter-box/resolver/device.resolver';
import { DeviceBoxResolver } from '@app/@shared/components/search-panel/device-detail/device-box/resolver/device-box.resolver';
import { EquipmentTypeHomeComponent } from './equipment-type/home/equipment-type-home.component';
import { EquipmentResolver } from './equipment/resolver/equipment.resolver';
import { EquipmentHomeComponent } from './equipment/home/equipment-home.component';
import { EquipmentTypeService } from '@app/@shared/services/map-elements-services/equipment-type.service';
import { TraceHomeComponent } from './trace/home/trace-home.component';
import { ContainerHomeComponent } from './container/home/container-home.component';
import { SplitterTypeComponent } from './splitter-type/home/splitter-type.component';
import { UsersHomeComponent } from './users/home/users-home.component';
import { OverlappingRingsComponent } from './home/overlapping-rings/overlapping-rings.component';
import { InfrastructureHomeComponent } from './infrastructure/home/infrastructure-home.component';
import { HomeComponent } from './home/home.component';
import { ElementTypeHomeComponent } from './element-type/home/element-type-home.component';
import { PlatformGuard } from '@app/@shared/guards/platform.guard';
import { AuthenticationGuard } from '@app/auth';
import { TraceDiagramComponent } from '@app/@shared/components/d3/horizontal-tree-container-device/trace-diagram/trace-diagram.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':platform/home',
      //redirectTo: ':platform/overlapping-rings',
      component: HomeComponent,
      data: { title: marker('Home') },
    },
    {
      path: ':platform/map-viewer',
      component: MapViewerComponent,
      data: { title: marker('Network mantenance') },
      canActivate: [PlatformGuard, AuthenticationGuard],
      resolve: {
        searchPanelData: SearchPanelDataResolver,
      },
    },
    {
      path: ':platform/map-viewer/infrastructure/:infrastructureID',
      component: InfrastructureODFComponent,
      data: { title: marker('Infrastructure ODF') },
      canActivate: [PlatformGuard, AuthenticationGuard],
      resolve: {
        infrastructure: InfrastructureResolver,
        infrastructureODF: InfrastructureODFResolver,
      }
    },
    {
      path: ':platform/map-viewer/splitter/:deviceID',
      component: SplitterBoxComponent,
      resolve: {
        device: DeviceResolver,
        splitterBox: SplitterBoxResolver,
      },
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Splitter Box') },
    },
    {
      path: ':platform/map-viewer/device/:deviceID',
      component: DeviceBoxComponent,
      data: { title: marker('Device Box') },
      canActivate: [PlatformGuard, AuthenticationGuard],
      resolve: {
        devices: CommonDevicesResolver,
        deviceBox: DeviceBoxResolver,
      },
    },
    {
      path: ':platform/map-viewer/device/:deviceID/:selectedTab',
      component: DeviceBoxComponent,
      data: { title: marker('Device Box') },
      canActivate: [PlatformGuard, AuthenticationGuard],
      resolve: {
        devices: CommonDevicesResolver,
        deviceBox: DeviceBoxResolver,
      },
    },
    {
      path: ':platform/map-viewer/container/:containerID',
      component: DeviceBoxComponent,
      data: { title: marker('Device Box') },
      canActivate: [PlatformGuard, AuthenticationGuard],
      resolve: {
        deviceBox: DeviceBoxResolver
      }
    },
    {
      path: ':platform/survey',
      component: HomeSurveyComponent,
      data: { title: marker('Survey') },
    },
    {
      path: ':platform/feasibility',
      component: FeasibilityHomeComponent,
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Feasibility') },
    },
    {
      path: ':platform/devices',
      component: DeviceHomeComponent,
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Device') },
      resolve: {
        devices: DevicesAndContainersResolver,
      },
    },
    {
      path: ':platform/equipment-type',
      component: EquipmentTypeHomeComponent,
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Equipment-type') },
    
    },
    {
      path: ':platform/trace',
      component: TraceHomeComponent,
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Trace') },
    
    },
    {
      path: ':platform/container',
      component: ContainerHomeComponent,
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Container') },
    
    },
    {
      path: ':platform/element-type',
      component: ElementTypeHomeComponent,
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Element-type') },
    },
    {
      path: ':platform/splitter-type',
      component: SplitterTypeComponent,
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Splitter-type') },
    },
    {
      path: ':platform/equipment',
      component: EquipmentHomeComponent,
      data: { title: marker('OLT') },
      canActivate: [PlatformGuard, AuthenticationGuard],
      resolve: {
        equipments: EquipmentResolver,
      },
    },
    {
      path: ':platform/users',
      component: UsersHomeComponent,
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Users') },
    },
    {
      path: ':platform/infrastructure',
      component: InfrastructureHomeComponent,
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Infrastructure') },
    },
    {
      path: ':platform/trace-diagram',
      component: TraceDiagramComponent,
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Trace Diagram') },
    },
    {
      path: ':platform/trace-diagram/:mslink',
      component: TraceDiagramComponent,
      canActivate: [PlatformGuard, AuthenticationGuard],
      data: { title: marker('Trace Diagram') },
    },

  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    MainService,
    UtilsService,
    DataSourceService,
    TraceService,
    ContainerService,
    DeviceService,
    InfrastructureService,
    ConnectionService,
    ServiceService,
    EquipmentTypeService,  
  ],
})
export class GeneralRoutingModule {}
