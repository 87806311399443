// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './env';

export const environment = {
  local: false,
  dev: false,
  production: true,
  qa: false,
  api: 'claro_production',
  version: env['npm_package_version'],
  serverUrl: 'http://192.168.1.9:5051/api/',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'es-AR'],
  googleApiKey:"AIzaSyBoJlYC9pqtuhqJWLtg8Zd4j71U9xTcUa8",
  jwtEncode:'MiSuperClaveSecreta123!',
  scriptSrcHtml2Canvas:'https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js'

};
